import React, { useEffect, useState } from "react";
import styled from "styled-components";

function Loading() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <BackgroundVideo autoPlay muted loop>
        <source src={isMobile ? "./videoMobile.mp4" : "./cover.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </BackgroundVideo>
      <h2>Scroll down to reveal our story</h2>
      <div className="effect"></div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  h2 {
    font-size: 17px;
    position: absolute;
    bottom: 5%;
    z-index: 1;

    @media only screen and (max-width: 720px) {
      font-size: 14px;
    }
  }

  .effect {
    position: absolute;
    bottom: 5%;
    z-index: 1;
  }
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

export default Loading;
