// src/components/WhyAreWe.js

import React from 'react';
import styled from 'styled-components';

function WhyAreWe() {
  return (
    <Container>
      <Wrap>
        <Left>
          <h1>WHY ARE WE?</h1>
        </Left>
        <Right>
          <Slot>
            <h1>Innovation at Our Core</h1>
            <p className='para'>At Ceriture, innovation is the heartbeat of everything we do. We continuously push the boundaries of technology, combining cutting-edge solutions with creative thinking to deliver unique, high-impact results. Our commitment to staying ahead of the curve ensures that our clients benefit from the latest advancements and innovative approaches that set them apart in the competitive digital landscape.</p>
          </Slot>

          <Slot>
            <h1>Expertise You Can Trust</h1>
            <p className='para'>Our team is composed of seasoned professionals with a wealth of experience in software, web development, and brand development. This deep expertise allows us to tackle complex challenges and deliver solutions that are both effective and reliable. We pride ourselves on our meticulous attention to detail and our ability to provide expert guidance, ensuring that every project we undertake is executed to the highest standards.</p>
          </Slot>

          <Slot>
            <h1>Results That Matter</h1>
            <p className='para'>We are driven by a results-oriented mindset, focusing on delivering tangible outcomes that make a real difference for our clients. Our success stories speak volumes about our ability to transform businesses, enhance digital presence, and drive growth. By prioritizing measurable results, we ensure that our solutions not only meet but exceed our clients' expectations, creating lasting value and achieving significant impact.</p>
          </Slot>
        </Right>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`;

const Wrap = styled.div`
  width: 90%;
  height: 100%;
  display: flex;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 50%;
  padding: 0 100px;

  h1 {
    font-size: 150px;
    text-align: left;

    @media only screen and (max-width: 768px) {
      font-size: 80px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }
`;

const Right = styled.div`
  width: 50%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const Slot = styled.div`
  border-bottom: 1px solid white;
  padding: 10px 0;
  margin: 15px 0;

  h1 {
    font-size: 22px;
    margin: 10px 0;
    color: #c2f970;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  p.para {
    font-size: 16px;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export default WhyAreWe;
