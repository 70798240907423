import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

function Feedback() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
      'service_5jayu3c',
      'template_g87ojpk',
      {
        from_name: formData.name,
        from_email: formData.email,
        contact_number: formData.contactNumber,
      },
      '7vkjif-MYv_kfcrQI'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }).catch((error) => {
      console.log('FAILED...', error);
    });
  };

  return (
    <Container>
      <Wrap>
        <Left>
          <h1>CONTACT</h1>
          <Address>
            <p>Negombo RD,</p>
            <p>Katuneriya,</p>
            <p>Wennappuwa.</p>
          </Address>
          <Social>
            <h2><a href='https://www.facebook.com/ceriture?mibextid=JRoKGi'>Facebook</a></h2>
            <h2><a href='https://www.linkedin.com/company/ceriture/'>Linkedin</a></h2>
            <h2><a href='https://www.instagram.com/ceriture_'>Instagram</a></h2>
          </Social>

          <Parag>
            <p>At Ceriture, we're dedicated to providing innovative and personalized digital solutions. Whether you're looking to launch a new project, revamp your digital presence, or seek expert advice, we're here to help. Our team of professionals combines creativity with cutting-edge technology to deliver exceptional results that drive success and growth for your business. Contact us today to learn more about how we can partner with you to achieve your goals.</p>
          </Parag>
        </Left>
        <Right>
          <form className='form' onSubmit={handleSubmit}>
            <InputField label="Your Name" name="name" value={formData.name} onChange={handleChange} />
            <InputField label="Email Address" name="email" value={formData.email} onChange={handleChange} />
            <InputField label="Contact Number" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />
            <StyledButton type="submit">
              Contact Us
            </StyledButton>
          </form>
        </Right>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 70vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;

const Wrap = styled.div`
  width: 95%;
  height: 100%;
  display: flex;

  @media only screen and (max-width: 1200px){
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 50%;

  a{
  text-decoration: none;
  color: #fff;
  }

  h1{
    font-size: 80px;
    margin: 20px 0;

    @media only screen and (max-width: 1200px){
      font-size: 60px;
    }
  }

  @media only screen and (max-width: 1200px){
    width: 100%;
  }
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  padding-left: 50px;

  .form{
    width: 100%;
  }

  @media only screen and (max-width: 1200px){
    width: 100%;
    padding-left: 0px;
  }
`;

const Address = styled.div`
  width: 100%;
  //padding: 0 10px;
`;

const Parag = styled.div`
  padding-left: 40px;

  
  @media only screen and (max-width: 1200px){
    padding: 0;
  }

`;

const Social = styled.div`
  display: flex;
  padding: 20px 0;

  h2{
    margin-right:  20px;
  }

  @media only screen and (max-width: 1200px){
    display: flex;
    //align-items: center;
    flex-direction: column;
  }
`;

const FormGroup = styled.div`
  position: relative;
  padding: 20px 0 0;
  width: 100%; /* Ensure the FormGroup takes full width */
  margin: 20px 0;
`;

const FormField = styled.input`
  font-family: inherit;
  width: 100%; 
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 17px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown + label {
    font-size: 17px;
    cursor: text;
    top: 20px;
  }

  &:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #C2F970, #C2F970);
    border-image-slice: 1;
  }

  &:focus + label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #C2F970;
    font-weight: 700;
  }

  &:required,
  &:invalid {
    box-shadow: none;
  }
`;

const FormLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #9b9b9b;
  pointer-events: none;
`;

const InputField = ({ label, name, value, onChange }) => (
  <FormGroup>
    <FormField type="text" name={name} placeholder={label} value={value} onChange={onChange} required />
    <FormLabel>{label}</FormLabel>
  </FormGroup>
);

const StyledButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 4rem;
  width: 16rem;
  border: none;
  text-align: left;
  padding: 0.75rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #C2F970;
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color:  #FEE771;
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #60DBEA;
    color: #C2F970;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;

export default Feedback;
