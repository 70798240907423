import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

function Hero({ opacity, scrollToContact }) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container opacity={opacity}>
      <Left data-aos="fade-right">
        <h1>
          Creativity through <br/><strong className="topic">1s and 0s</strong>
        </h1>
        <p className="para">
          Welcome to Ceriture, where innovation meets expertise. At our core, we
          are a dynamic software, web, mobile development, and brand development
          company committed to transforming your digital presence. We blend
          creativity with technology, crafting solutions that not only meet your
          needs but also inspire and engage. Whether you're a startup, an
          established business, or a general business owner, we're here to turn
          your vision into reality with professionalism and creativity. Dive
          deeper to learn about our journey and how we can partner with you for
          success.
          <br></br>
          <StyledButton onClick={scrollToContact}>Contact</StyledButton>
        </p>
      </Left>
      <Right data-aos="fade-left">
        <img src="./hero3.png" alt="Hero" />
      </Right>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin-left:50px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 10px;
    margin-left:0px;
  }

  h1 {
    font-size: 60px;
    text-align: left;
    margin-top: 80px;

    .topic{
      margin-left: 50px;
    }

    @media only screen and (max-width: 1200px) {
      font-size: 55px;
      text-align: center;
      .topic{
      margin-left: 0px;
    }
    }
  }

  p {
    padding: 0 50px;

    @media only screen and (max-width: 1200px) {
      padding: 10px;
    }
  }
`;

const Right = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 90%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 4rem;
  width: 16rem;
  border: none;
  text-align: left;
  padding: 0.75rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 30px 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #C2F970;
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color:  #FEE771;
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #60DBEA;
    color: #C2F970;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;

export default Hero;
