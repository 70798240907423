// src/components/Process.js

import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  
  @media (max-width: 768px) {
    padding: 10px;
    height: 70vh;
  }
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const glitch = keyframes`
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
`;

const CenteredText = styled.h1`
  color: white;
  font-size: 3em;
  z-index: 2;
  animation: ${glitch} 1.5s infinite;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2em;
  }

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
`;

const StyledButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 4rem;
  width: 16rem;
  border: none;
  text-align: left;
  padding: 0.75rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #8b5cf6; /* violet-500 */
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color: #f43f5e; /* rose-300 */
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #fb7185; /* rose-300 */
    color: #fb7185; /* rose-300 */
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;


function Process() {
  return (
    <Container>
      <BackgroundVideo autoPlay loop muted>
        <source src="./video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </BackgroundVideo>
      <Overlay />
      <CenteredText>We've achieved so much, yet there's so much more to conquer. Become a part of our amazing story.</CenteredText>
      {/* <StyledButton>Contact Us</StyledButton> */}
    </Container>
  );
}

export default Process;
