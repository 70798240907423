// src/Carousel.js
import React, { useState } from 'react';
import styled from 'styled-components';

const projects = [
  { id: 1, image: './1.png', number: '', name: 'Project Name 1' },
  { id: 2, image: './2.png', number: '', name: 'Project Name 2' },
  { id: 3, image: './3.png', number: '', name: 'Project Name 3' },
  { id: 4, image: './4.png', number: '', name: 'Project Name 4' },
  { id: 5, image: './5.png', number: '', name: 'Project Name 5' },
  { id: 6, image: './6.png', number: '', name: 'Project Name 6' },
  { id: 7, image: './7.jpg', number: '', name: 'Project Name 7' }
];

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CarouselContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  background: black;
`;

const CarouselSlide = styled.div`
  min-width: 20%;
  margin: 0 10px;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  transform: ${(props) => `translateX(${-props.current * 100}%)`};

  @media (max-width: 1200px) {
    min-width: 25%;
  }
  @media (max-width: 992px) {
    min-width: 33.33%;
  }
  @media (max-width: 768px) {
    min-width: 50%;
  }
  @media (max-width: 576px) {
    min-width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ProjectInfo = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 1.5rem;
`;

const ProjectNumber = styled.span`
  font-size: 4rem;
  font-weight: bold;
`;

const CarouselControl = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;

  &.prev {
    left: 10px;
  }

  &.next {
    right: 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  background: black;

  h1{
    margin: 40px 0;
  }

`

const Projects = () => {
  const [current, setCurrent] = useState(0);

  const handlePrev = () => {
    setCurrent((prev) => (prev === 0 ? projects.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrent((prev) => (prev === projects.length - 1 ? 0 : prev + 1));
  };

  return (
    <Container>
      <h1>Projects</h1>
      <CarouselWrapper>
        <CarouselContainer>
          {projects.map((project, index) => (
            <CarouselSlide key={project.id} current={current}>
              <Image src={project.image} alt={project.name} />
              <ProjectInfo>
                <ProjectNumber>{project.number}</ProjectNumber>
                {/* <span>{project.name}</span> */}
              </ProjectInfo>
            </CarouselSlide>
          ))}
        </CarouselContainer>
        <CarouselControl className="prev" onClick={handlePrev}>&larr;</CarouselControl>
        <CarouselControl className="next" onClick={handleNext}>&rarr;</CarouselControl>
      </CarouselWrapper>
    </Container>
  );
};

export default Projects;
