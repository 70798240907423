import React, { useRef, useState, useEffect } from 'react';
import { useScroll } from 'react-use';
import Hero from './Pages/Hero';
import Loading from './Pages/Loading';
import Humain from './Pages/Humain';
import Service from './Pages/Service';
import './App.css';
import ReviewCarousel from './Pages/Reveiws';
import WhyAreWe from './Pages/WhyAreWe';
import Projects from './Pages/Projects';
import Footer from './Pages/Footer';
import Contact from './Pages/Contact';
import Process from './Pages/Process';
import Blog from './Pages/Blog';
import Discount from './Pages/Discount';

function App() {
  const components = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [currentComponent, setCurrentComponent] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollToComponent = (index) => {
    components[index].current.scrollIntoView({ behavior: 'smooth' });
  };

  const { y } = useScroll(window);

  const handleScroll = () => {
    if (isScrolling) return;

    const deltaY = y - window.scrollY;
    const delta = Math.sign(deltaY);
    setIsScrolling(true);

    if (delta > 0 && currentComponent < components.length - 1) {
      setCurrentComponent((prev) => prev + 1);
    } else if (delta < 0 && currentComponent > 0) {
      setCurrentComponent((prev) => prev - 1);
    }

    setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  useEffect(() => {
    const scrollListener = () => {
      handleScroll();
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [currentComponent, isScrolling]);

  useEffect(() => {
    scrollToComponent(currentComponent);
  }, [currentComponent]);

  return (
    <div className="App custom-cursor-custom">
      <div ref={components[0]}><Loading /></div>
      <div ref={components[1]}><Hero /></div>
      <div ref={components[2]}><Humain /></div>
      <div ref={components[3]}><Service /></div>
      <div ref={components[4]}><ReviewCarousel /></div>
      <div ref={components[5]}><WhyAreWe /></div>
      <div ref={components[6]}><Projects /></div>
      <div ref={components[7]}><Blog /></div>
      <div ref={components[8]}><Contact /></div>
      <div ref={components[9]}><Discount /></div>
      <div ref={components[10]}><Process /></div>
      <div ref={components[11]}><Footer /></div>
    </div>
  );
}

export default App;
