import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

function Hero({ opacity, scrollToContact }) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container opacity={opacity}>
      <Right data-aos="fade-left">
        <img src="./hero2.png" alt="Hero" />
      </Right>
      <Left data-aos="fade-right">
        <div className="content">
          <h1>
            hum<strong>AI</strong>n approach
          </h1>
          <p className="para">
            At Ceriture, our humAIn approach seamlessly blends human creativity
            with artificial intelligence. By leveraging AI for data analysis,
            automation, and trend prediction, we deliver fast, accurate results.
            Meanwhile, our talented team ensures that each solution is
            personalized and creatively aligned with your brand’s goals. This
            collaboration fosters open communication and trust, involving you at
            every stage. By combining the precision of AI with human insight, we
            drive success and growth, delivering exceptional, tailored solutions
            {/* <StyledButton onClick={scrollToContact}>Contact</StyledButton> */}
          </p>
        </div>
      </Left>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity};

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 55%;
  min-height: 100vh;
  display: flex;
  padding: 40px;
  border-left: 4px solid #c2f970;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 0;
  }
  .content {
    position: absolute;
    top: 15%;
    left: 5%;

    @media only screen and (max-width: 1200px) {
      position: sticky;
    }
  }

  h1 {
    font-size: 80px;
    text-align: left;

    @media only screen and (max-width: 1200px) {
      font-size: 55px;
      text-align: center;
    }
  }

  p {
    padding: 0 50px;

    @media only screen and (max-width: 1200px) {
      padding: 25px;
    }
  }
`;

const Right = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 4rem;
  width: 16rem;
  border: none;
  text-align: left;
  padding: 0.75rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 30px 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #c2f970;
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color: #fee771;
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #60dbea;
    color: #c2f970;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;

export default Hero;
