import React, { useState } from "react";
import styled from 'styled-components';
import "./blog.css";

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [popupContent, setPopupContent] = useState({ title: '', description: '', imageUrl: '' });

  const handleButtonClick = (title, description, imageUrl) => {
    setPopupContent({ title, description, imageUrl });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="body">
      <h1>Research and Articles</h1>
      
      <div className="cards-container">
        {blogPosts.map((post, index) => (
          <div className="card" key={index}>
            <div className="card-content">
              <h2 className="card-title">{post.title}</h2>
              <p className="card-body">{post.description}</p>
              <StyledButton onClick={() => handleButtonClick(post.title, post.description, post.imageUrl)}>More</StyledButton>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <PopupOverlay onClick={handleClose}>
          <Popup onClick={(e) => e.stopPropagation()}>
            <PopupHeader>
              <PopupImage src={popupContent.imageUrl} alt={popupContent.title} />
              <PopupTitle>{popupContent.title}</PopupTitle>
            </PopupHeader>
            <PopupDescription>{popupContent.description}</PopupDescription>
            <CloseButton onClick={handleClose}>Close</CloseButton>
          </Popup>
        </PopupOverlay>
      )}
    </div>
  );
};

const blogPosts = [
  { title: "Just Around the Corner!", description: "Stay Tuned... " , imageUrl: "./soon.jpg" },
  { title: "Just Around the Corner!", description: "Stay Tuned...", imageUrl: "./soon.jpg" },
  { title: "Just Around the Corner!", description: "Stay Tuned...", imageUrl: "./soon.jpg" },
  { title: "Just Around the Corner!", description: "Stay Tuned...", imageUrl: "./soon.jpg" }
];

export default Blog;

const StyledButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 3rem;
  width: 8rem;
  border: none;
  text-align: left;
  padding: 0.5rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #C2F970;
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color: #FEE771;
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #60DBEA;
    color: #C2F970;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  background: #0b0a0c;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const PopupImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 0.5rem;
  margin-right: 1rem;
`;

const PopupTitle = styled.h2`
  margin: 0;
`;

const PopupDescription = styled.p`
  margin-bottom: 2rem;
  max-height: 300px;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 3rem;
  width: 8rem;
  border: none;
  text-align: left;
  padding: 0.5rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #C2F970;
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color: #FEE771;
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #60DBEA;
    color: #C2F970;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;
