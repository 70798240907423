import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";

const SERVICE_ID = "service_ggffnu7";
const TEMPLATE_ID = "template_g87ojpk";
const USER_ID = "7vkjif-MYv_kfcrQI";

function Discount({ opacity }) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [formData, setFormData] = useState({
    discount_code: "",
    from_name: "",
    email: "",
    contact_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Sending the following data to EmailJS:", formData); // Log form data
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          // Handle successful email sending (e.g., show a success message)
        },
        (err) => {
          console.log("FAILED...", err);
          // Handle failed email sending (e.g., show an error message)
        }
      );
  };

  return (
    <Container opacity={opacity}>
      <Left data-aos="fade-right">
        <h1>
          Redeem your <strong>discount</strong>
        </h1>
        <form onSubmit={handleSubmit}>
          <InputField
            label="Discount Code"
            name="discount_code"
            value={formData.discount_code}
            onChange={handleChange}
          />
          <InputField
            label="Your Name"
            name="from_name"
            value={formData.from_name}
            onChange={handleChange}
          />
          <InputField
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <InputField
            label="Contact Number"
            name="contact_number"
            value={formData.contact_number}
            onChange={handleChange}
          />
          <StyledButton type="submit">
            Submit
          </StyledButton>
        </form>
      </Left>
      <Right data-aos="fade-left">
        <img src="./hero.png" alt="Hero" />
      </Right>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  padding: 40px;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 10px;
    align-items: center;
  }

  h1 {
    font-size: 60px;
    text-align: left;
    margin-top: 80px;

    @media only screen and (max-width: 1200px) {
      font-size: 55px;
      text-align: center;
    }
  }

  p {
    padding: 0 50px;

    @media only screen and (max-width: 1200px) {
      padding: 10px;
    }
  }

  form{
    width: 100%;
    padding-left: 45px;

    @media only screen and (max-width: 1200px) {
      padding: 0px;
    }
  }
`;

const Right = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;


  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
       @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const FormGroup = styled.div`
  position: relative;
  padding: 20px 0 0;
  width: 80%;
  margin: 20px 0;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const FormField = styled.input`
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 17px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown + label {
    font-size: 17px;
    cursor: text;
    top: 20px;
  }

  &:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #C2F970, #C2F970);
    border-image-slice: 1;
  }

  &:focus + label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #C2F970;
    font-weight: 700;
  }

  &:required,
  &:invalid {
    box-shadow: none;
  }
`;

const FormLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #9b9b9b;
  pointer-events: none;
`;

const InputField = ({ label, name, value, onChange }) => (
  <FormGroup>
    <FormField
      type="text"
      placeholder={label}
      name={name}
      value={value}
      onChange={onChange}
      required
    />
    <FormLabel>{label}</FormLabel>
  </FormGroup>
);

const StyledButton = styled.button`
  position: relative;
  background-color: #212121;
  height: 4rem;
  width: 16rem;
  border: none;
  text-align: left;
  padding: 0.75rem;
  color: #fefefe;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
  }

  &::before {
    width: 3rem;
    height: 3rem;
    right: 0.25rem;
    top: 0.25rem;
    background-color: #C2F970; /* violet-500 */
    filter: blur(20px);
  }

  &::after {
    width: 5rem;
    height: 5rem;
    background-color:  #FEE771; /* rose-300 */
    right: 2rem;
    top: 0.75rem;
    filter: blur(20px);
  }

  &:hover {
    border-color: #60DBEA; /* rose-300 */
    color: #C2F970; /* rose-300 */
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all 0.5s;

    &::before {
      right: 3rem;
      bottom: -2rem;
      box-shadow: 20px 20px 20px 30px #a21caf;
    }

    &::after {
      right: -2rem;
    }
  }
`;

export default Discount;
