import React, { useEffect } from "react";
import AOS from "aos";
import styled from "styled-components";

function Service() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <Container>
      <Left>
        <Slot>
          <h1>Web Development</h1>
          <p className="para">
            Our team specializes in crafting dynamic and responsive web
            applications tailored precisely to your business needs. Leveraging
            the latest technologies and industry best practices, we deliver
            robust, scalable, and secure web solutions. From intuitive user
            interfaces to seamless back-end functionality, we ensure your web
            application not only looks exceptional but also performs flawlessly.
          </p>
        </Slot>
        <Slot>
          <h1>Web3 Development</h1>
          <p className="para">
            Embark on a journey into the decentralized realm with Ceriture's
            cutting-edge Web3 development services. We excel in building secure,
            transparent, and efficient blockchain-based applications that
            empower businesses to harness the full potential of decentralized
            technologies. Whether it's creating smart contracts, developing
            decentralized apps (dApps), or integrating blockchain solutions,
            Ceriture serves as your trusted partner in navigating and thriving
            within the Web3 ecosystem.
          </p>
        </Slot>
        <Slot>
          <h1>Software Development</h1>
          <p className="para">
          At Ceriture, our software development services are meticulously designed to tackle complex business challenges with tailored software solutions. We follow a rigorous development process to ensure your software meets your specific requirements and exceeds expectations. From enterprise-grade applications to innovative software products, we deliver solutions that drive efficiency, foster innovation, and fuel business growth.
          </p>
        </Slot>
        <Slot>
          <h1>Brand Development - Ceriture Viral</h1>
          <p className="para">
          Build a resilient and impactful brand with our comprehensive brand development services. From strategic brand positioning to identity design and digital marketing, we collaborate closely with you to create a cohesive and compelling brand identity. Our goal is to craft a brand that resonates deeply with your target audience, enhances market presence, and establishes a lasting impression in the competitive landscape.
          </p>
        </Slot>
        <Slot>
          <h1>Mobile Application Development</h1>
          <p className="para">
          Stay ahead in the mobile-first era with our exceptional mobile application development services. We specialize in creating intuitive and engaging mobile apps for both iOS and Android platforms, ensuring seamless user experiences across devices. From initial concept to deployment, our team manages every phase of the development process, delivering apps that not only delight users but also drive tangible business outcomes.
          </p>
        </Slot>
      </Left>
      <Right>
        <h1>SERVICES</h1>
      </Right>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const Wrap = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  border: 1px solid white;
`;

const Left = styled.div`
  width: 75%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;

  h1 {
    color: #c2f970;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const Right = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: #121212;
  overflow-y: hidden;
  background: linear-gradient(
    135deg,
    #121212 25%,
    #1a1a1a 25%,
    #1a1a1a 50%,
    #121212 50%,
    #121212 75%,
    #1a1a1a 75%,
    #1a1a1a
  );
  background-size: 40px 40px;

  /* Animation */
  animation: move 4s linear infinite;
  position: relative;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: 200px;
  }

  h1 {
    position: absolute;
    top: 34%;
    font-size: 140px;
    z-index: 1000;
    color: black;
    transform: rotate(90deg);

    @media only screen and (max-width: 1200px) {
      position: sticky;
      transform: rotate(0deg);
      font-size: 65px;
    }
  }
`;

const Slot = styled.div`
  margin: 20px 0;

  h1 {
    font-size: 25px;
    @media only screen and (max-width: 1200px) {
      text-align: center;
    }
  }
`;

export default Service;
