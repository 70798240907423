// src/components/ReviewCarousel.js
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const reviews = [
  { id: 1, user: "Thushara Dissanayake", position: "Founder of YooGoo", stars: 5, review: "Ceriture has been very helpful and communicative. I believe this is the best service I could ever find on any SriLanken platform. Clean code. Good job! Highly recommended." },
  { id: 2, user: "Dominic J. Stella", position: "Founder of Metamoth", stars: 5, review: "Definitely recommend. very good communication, great quality work and very satisfied delivery time frame. we found them to be nothing but professional, but in an easy going way. they really worked with us for us to achieve our desired outcome, cannot fault them. we look forward to working with them again!." },
  { id: 3, user: "Daniel Alexander Froreich", position: "Founder of AssetAvenue", stars: 5, review: "As the founder of AssetAvenue, I am delighted to share our exceptional experience working with the development team at Ceriture. Their communication has been outstanding, ensuring that we were always informed and involved throughout the project. The quality of their work surpassed our expectations, and they consistently delivered within the agreed time frames. The team at Ceriture demonstrated professionalism in every aspect, yet maintained an approachable and easy-going manner. They collaborated with us closely to ensure our vision was realized, and we couldn't be more satisfied with the results. We have found no faults in their service and are eager to partner with them again for future projects. We highly recommend Ceriture for anyone seeking top-notch development services." },
  { id: 4, user: "John Carlos", position: "CEO of TechSolutions", stars: 5, review: "I recently had the pleasure of working with Ceriture, and the experience was exceptional. The team demonstrated remarkable professionalism and thoroughness in developing my website. Their attention to detail ensured the final product was both visually appealing and highly functional. Communication was outstanding; they kept me informed, welcomed feedback, and incorporated all my ideas seamlessly. Their great attitude and dedication to delivering a high-quality product were evident throughout the project. I highly recommend Ceriture for anyone seeking top-notch web development services." },
  { id: 5, user: "Emma Cooray", position: "Director at Innovate Designs", stars: 5, review: "I recently collaborated with Ceriture, and the experience was simply amazing! Their team exceeded all my expectations, delivering a website that was both stunning and incredibly functional. The attention to detail and dedication to quality were evident in every aspect of their work. Communication was seamless, and they were always receptive to my feedback, ensuring that my vision was brought to life perfectly. I couldn’t have asked for a better team, and I am thrilled with the final result. I highly recommend Ceriture to anyone in need of professional web development services." },
  // Add more reviews as needed
];

const CarouselContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 40px;
  padding-top: 80px;
  background: black;
  overflow: hidden; 

  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 40px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    padding-top: 20px;
  }
`;

const ReviewSlide = styled.div`
  width: 70%;
  background: black;
 // border: 1px solid #9EFAFC;
  height: 100%; 
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  overflow: hidden; 

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 10px;
  }
`;

const UserName = styled.h3`
  margin-bottom: 10px;
  color: #fff;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const UserPosition = styled.h4`
  margin-bottom: 10px;
  color: #ccc;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const UserStars = styled.div`
  margin-bottom: 10px;
  color: #ffd700;
`;

const UserReview = styled.p`
  margin-top: 30px;
  font-size: 16px;
  color: #fff;

  @media (max-width: 480px) {
    font-size: 14px;
    margin-top: 20px;
  }
`;

const ReviewCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {reviews.map((review) => (
          <ReviewSlide key={review.id}>
            <UserName>{review.user}</UserName>
            <UserPosition>{review.position}</UserPosition>
            <UserStars>{'⭐'.repeat(review.stars)}</UserStars>
            <UserReview>{review.review}</UserReview>
          </ReviewSlide>
        ))}
      </Slider>
    </CarouselContainer>
  );
}

export default ReviewCarousel;
